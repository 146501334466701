<template>
  <div class="row justify-content-center align-items-md-center">
    
    <div class="col-md-8">

        <div v-for="lista in lista_avisos" :key="lista.id">
          
      
      <div class="card full-height bg-ligth mb-4 shadow-lg p-3 mb-5 bg-white rounded">
        <div class="card-header d-flex justify-content-between">
          <h3 class="mb-0">{{lista.titulo}}</h3>
               <span style="font-size:12px;">{{lista.created_at}}</span>
        </div>
      
        <div class="card-body">
        
          {{lista.conteudo}}
        
   
        </div>
      </div>
    </div>
      </div>
  </div>
</template>

<script>
import {SET_BREADCRUMB} from "@/core/store/breadcrumbs.module";
import dateFormat from "@/core/mixins/dateFormat";

export default {
  mixins:{dateFormat},
  data() {
    return {
         currentPage: 1,
      perPage: 10
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{title: "Aviso"}]);
  },
  created() {
    this.listar_tipos_aviso();
  },
  computed: {
    lista_avisos() {
      return this.$store.state.aviso.lista_avisos;
    },
    lista_permissoes_filial() {
      return this.$store.state.sistema.lista_permissoes_filial;
    },
    mensagem_alert() {
      return this.$store.state.aviso.mensagem_alert;
    },
  },
  methods: {
    async listar_tipos_aviso() {
      this.$store.dispatch("configEmpresa/MudarPreloader", true);
      await this.$store.dispatch("aviso/listar_avisos").finally(() => {
        this.$store.dispatch("configEmpresa/MudarPreloader", false);
        this.$store.dispatch("configEmpresa/atualizar", "");
      });
    },
    atualizar(value) {
      this.$router.push({name: "createTipoProduto"});
      this.$store.dispatch("configEmpresa/atualizar", value);
    },
    async confirm(value) {
      await this.$confirm({
        title: "Estas seguro?",
        message: `Esta ação bloqueara esta Sala no sistema?`,
        button: {
          no: "No",
          yes: "Si",
        },
        callback: (confirm) => {
          if (confirm) {
            this.bloquear(value);
          }
        },
      });
    },
    async bloquear(value) {
      await this.$store.dispatch("configEmpresa/bloquear_sala", value);
      this.alertMessage(this.mensagem_alert.tipo, this.mensagem_alert.message);
    },
    alertMessage(tipo, mensagem) {
      this.$fire({
        title: "Aviso",
        text: mensagem,
        type: tipo,
        timer: 3000,
      }).then((r) => {
        console.log(r.value);
      });
    },
  },
};
</script>

<style scoped>
.lista:hover {
  background-color: rgba(167, 183, 255, 0.705);
}
</style>